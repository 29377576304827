require.context('../../images', true, /^(?!.*\.(?:xcf|psd|ai|zip|xcf|txt|pdf|URL)$).*/);
import "../../stylesheets/base";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "element-remove";

import 'jquery-migrate';
import 'antiscroll';
import 'blueimp-file-upload';

window.$ = $;
window.jQuery = jQuery;
